import {faLessThanEqual} from '@fortawesome/free-solid-svg-icons'
import businessobject_data from '../../data/businessobject_data'
import data from '../../data/_data'
import message_data from './../../data/message_data'

/**
 * Sammlung einiger Funktionen, primär zum übertragen in die options von React-Select
 *
 * @author DHR
 */

/**
 * Kopiert alle Werte eines Objektes, auch Arrays und Objekte, in ein neues Objekt um keine Referenzen zu übergeben
 *
 * @param {Object} defaultObject - Objekt, dessen Werte kopiert werden sollen
 * @return Kopie des übergebenen Objektes ohne referenzen
 */
export function defaultEncoder(defaultObject) {
    let tempObject = {}
    if (!defaultObject) {
        return {}
    }
    for (let key of Object.keys(defaultObject)) {
        if (Array.isArray(defaultObject[key])) {
            tempObject[key] = [...defaultObject[key]]
        } else if (typeof defaultObject[key] === 'object' && defaultObject[key] !== null) {
            tempObject[key] = {...defaultObject[key]}
        } else {
            tempObject[key] = defaultObject[key]
        }
    }
    return tempObject
}

/**
 * Überträgt die ENUM Werte der Schnittstelle in entsprechende Texte zur Präesntation gegenüber dem Nutzer
 * um Laute und Leerzeichen werden ergänzt
 *
 * @param {String} enumValue - ENUM
 * @return Präsentationsfähiger Text
 */
export function enum2label(enumValue, compTyp) {
    if (enumValue && typeof enumValue === 'string') {
        if (enumValue === 'NONE') {
            return ''
        }
        if (compTyp && compTyp === 'anredetitel') {
            if (enumValue === 'DR') {
                return 'Dr.'
            } else if (enumValue === 'PROF') {
                return 'Prof.'
            } else if (enumValue === 'DIPLOM') {
                return 'Dipl.'
            }
        }
        let tempEnum = enumValue.replace('OE', 'Ö').replace('AE', 'Ä').replace('UE', 'Ü').replace('_u_', ' und ').replace('__', ', ').replace('_', ' ')
        if (tempEnum.includes(' ')) {
            let tString = ''
            for (let t of tempEnum.split(' ')) {
                if (t === 'und') {
                    tString += ' und '
                } else if (t.length < 4) {
                    tString += t + ' '
                }
                // else if (t.length < 8){
                //     tString += ' '+t.toLowerCase()
                // }
                else {
                    tString += t[0].toUpperCase() + t.substring(1).toLowerCase() + ' '
                }
            }
            return tString.trim()
        } else if (tempEnum.length > 3) {
            return tempEnum[0].toUpperCase() + tempEnum.substring(1).toLowerCase()
        } else {
            return tempEnum
        }
    } else {
        return enumValue
    }
}

/**
 * Überträgt die fieldData aus der Schnittstelle in ein Object mit jeweiliger Präsentation
 * fieldData={compTyp1: [VALUE1, VALUE2, ...], ...}
 * options={compTyp1: {value1: label1, value2: label2}, ...}
 *
 * @see enum2label
 * @param {Object} fieldData - fieldData aus der Schnittstelle
 * @return Options mit präsentationsfähigen Werten
 */
export function fieldData2options(fieldData) {
    const options = {}
    if (fieldData) {
        for (let compTyp of Object.keys(fieldData)) {
            options[compTyp] = {}
            for (let field of fieldData[compTyp]) {
                options[compTyp][field] = enum2label(field, compTyp)
            }
        }
    }
    return options
}

/**
 * Überträgt gruppierte options in einfache options
 *
 * @param {Object} groupedOptions - gruppierte Options
 * @return Options auf einer Ebene
 */
export function flattenGroupOptions2Options(groupedOptions) {
    let tempOpt = []
    for (let gr of groupedOptions) {
        if (gr.options && gr.options.length > 0) {
            tempOpt.push(...gr.options)
        }
    }
    return tempOpt
}

/**
 * Überträgt ein mehrfach geschachteltes Objekt in ein Array mit den values der letzten Ebene
 *
 * @param {Object} object - mehrfach geschachteltes Objekt
 * @param {int} flattenCount - bei mehrfach geschachteltem Objekt die anzahl der Ebenen, die geflattet werden sollen
 * @param {String} attribute - attribut value unter welchem der key des Objektes als Label gespeichert werden soll
 * @return Liste mit einer Ebene
 */
export function flattenObject2Array(object, flattenCount = 1, attribute) {
    let tempArray = []
    for (let key of Object.keys(object)) {
        if (object[key]) {
            if (flattenCount > 1) {
                tempArray.push(flattenObject2Array(object[key], flattenCount - 1))
            } else {
                if (attribute) {
                    for (let o of object[key]) {
                        tempArray.push({[attribute]: key, ...o})
                    }
                } else {
                    tempArray.push(...object[key])
                }
            }
        }
    }
    return tempArray
}

/**
 * Überträgt eine Liste von Objekten in eine Liste mit nur einer Ebene
 * Das Attribut welches aus dem Objekt genommen werden soll, muss übergeben werden
 *
 * @param {Array} objectList - Liste von Objekten
 * @param {String} attribute - Attribt welches aus dem Objekt genommen werden soll
 * @return Liste mit nur einer Ebene
 */
export function objectList2AttrList(objectList, attribute) {
    if (typeof objectList === 'string' || typeof objectList === 'number') {
        return objectList
    }
    let attributeList = []
    if (objectList) {
        for (let o of objectList) {
            if (typeof o === 'string' || typeof o === 'number') {
                attributeList.push(o)
            } else {
                attributeList.push(o[attribute])
            }
        }
    }
    return attributeList
}

/**
 * Überträgt ein Objekt in die optionen von React-Select
 * Nimmt dabei den key als value und den value als label
 *
 * @param {Object} object - objekt wessen attribute in React-Select als optionen stehen sollen
 * @returns Liste von Optionen für React-Select
 */
export function object2options(object) {
    let options = []
    if (object) {
        for (let v of Object.keys(object)) {
            options.push({value: v, label: object[v]})
        }
    }
    return options
}

/**
 * Überträgt ein Array in die optionen von React-Select
 * Nimmt dabei den wert als value und label, schreibt das Label aber groß
 *
 * @param {Array} list - Array wessen Einräge in React-Select als optionen stehen sollen
 * @returns Liste von Optionen für React-Select
 */
export function list2options(list) {
    let options = []
    for (let v of list) {
        options.push({value: v, label: v[0].toUpperCase() + v.substring(1)})
    }
    return options
}

/**
 * Überträgt ein Object in die gruppierten Optionen von React-Select
 * Nimmt dabei den key als Gruppe und den key als Liste zu Optionen
 *
 * @param {Object} dict - Object mit key-value-Paaren, values dabei als Liste wessen Einräge in React-Select als optionen stehen sollen
 * @returns Liste gruppierter Optionen für React-Select
 */
export function dict2options(dict) {
    let options = []
    for (let key of Object.keys(dict)) {
        let v = list2options(Object.keys(dict[key]))
        options.push({options: v, label: key})
    }
    return options
}

/**
 * Überträgt ein Object in die gruppierten Optionen von React-Select
 * Nimmt dabei den key als Gruppe und den key als objekt-liste zu Optionen
 *
 * @param {Object} dict - Object mit key-value-Paaren, values dabei als Liste wessen Einräge in React-Select als optionen stehen sollen
 * @param {String} name - key des Label attribut des Objekts
 * @param {Function} value - function welche den value zu einem key zurückgibt
 * @param {Object} flattenObj - optionales Object dass in den options ergänzt wird
 * @returns Liste gruppierter Optionen für React-Select
 */
export function objectDict2options(dict, name, value, flattenObj) {
    let options = []
    for (let key of Object.keys(dict)) {
        let v = objectList2options(dict[key], name, value, flattenObj ? {attr: flattenObj, value: key} : null)
        options.push({options: v, label: key})
    }
    return options
}

/**
 * Überträgt eine List aus Objekten in die optionen von React-Select
 * Nutzt dabei die Id als value und den name als label
 *
 * @param {Array} objectList - Liste aus Objekten
 * @param {String} name - key des Label attribut des Objekts
 * @param {Function} value - function welche den value zu einem key zurückgibt
 * @param {Object} flattenObj - optionales Object dass in den options ergänzt wird
 * @returns options als Liste von Objekten für React-Select
 */
export function objectList2options(objectList, name, value, flattenObject) {
    let options = []
    for (let o of objectList) {
        if (!value) {
            options.push({value: o.id, label: o[name]})
        } else {
            options.push({value: flattenObject ? {...value(o), [flattenObject.attr]: flattenObject.value} : value(o), label: o[name]})
        }
    }
    return options
}

/**
 * Extrahiert die Values der options von React-Select
 *
 * @param {Object} options - options von React-Select
 * @returns values der options als Liste
 */
export function options2values(options) {
    let values = []
    for (let o of options) {
        values.push(o.value)
    }
    return values
}

/**
 * Filtert eine Liste von Objekten
 *
 * @param {Array} data - Liste aus Objekten
 * @param {String} attribute - key des Attributes zu Filtern
 * @param {String} value - Wert des Attributes zu Filtern
 * @param {boolean} exclude - gibt an ob der Wert ausgeschlossen werden soll oder ob nach ihm gesucht wird
 * @returns gefilterte Liste von Objekten
 */
export function optionsFilter(data = [], attribute, value, exclude) {
    if (data) {
        if (Array.isArray(value)) {
            if (exclude) {
                return data.filter((obj) => {
                    if (!obj) {
                        return false
                    }
                    if (Array.isArray(obj[attribute])) {
                        return obj[attribute].filter((v) => value.includes(v)).length === 0
                    } else {
                        return !value.includes(obj[attribute])
                    }
                })
            } else {
                return data.filter((obj) => {
                    if (!obj) {
                        return false
                    }
                    if (Array.isArray(obj[attribute])) {
                        return obj[attribute].filter((v) => value.includes(v)).length > 0
                    } else {
                        return value.includes(obj[attribute])
                    }
                })
            }
        } else {
            if (exclude) {
                return data.filter((obj) => {
                    if (!obj) {
                        return false
                    }
                    if (Array.isArray(obj[attribute])) {
                        return !obj[attribute].includes(value)
                    } else {
                        return obj[attribute] !== value
                    }
                })
            } else {
                return data.filter((obj) => {
                    if (!obj) {
                        return false
                    }
                    if (Array.isArray(obj[attribute])) {
                        return obj[attribute].includes(value)
                    } else {
                        return obj[attribute] === value
                    }
                })
            }
        }
    }
}

/**
 * Vergleicht zwei Werte mit einander, die Werte könenn dabei auch Objekte sein, dessen einzelen Keys verglichen werden sollen
 *
 * @param {*} a - erster Wert
 * @param {*} b - zweiter Wert
 * @returns true wenn die Werte gleich sind, sonst false
 */
export function equals(a, b) {
    if ((typeof a === 'number' && typeof b === 'number') || (typeof a === 'boolean' && typeof b === 'boolean')) {
        return a === b
    }
    if ((a === undefined && b !== undefined) || (b === undefined && a !== undefined)) {
        return false
    }
    if ((a === undefined && b === undefined) || (a === null && b === null)) {
        return true
    }
    return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((key) => a[key] === b[key])
}

/**
 * Vergleich zwei Werte mit einander auf semantische konsistenz
 *
 * @param {*} value - erster Wert
 * @param {*} compareToValue - zweiter Wert
 * @param {Function} func - vergleichsfunktion
 * @returns true wenn die Werte gleich sind, sonst false
 */
export function semanticCheck(value, compareToValue, func) {
    if (value && compareToValue) {
        if (func === '<=') {
            return value <= compareToValue
        } else if (func === '>=') {
            return value >= compareToValue
        } else if (func === '==') {
            return value === compareToValue
        } else if (func === '!=') {
            return value !== compareToValue
        }
    }
    if (func === 'required') {
        return value ? !!compareToValue : true
    }
    return true
}

/**
 * Generiert eine Funktion die für einen Key die entsprechendne Optionen von React-Select zurückgibt
 * Bei Angabe von filterBy und filterValue wird zusätzlich gefiltert
 *
 * @param {Object} competenceData - Kompetenzdaten der Schnittstelle
 * @param {Object} fieldData - fieldData der Schnittstelle
 * @returns eine Funktion die für einen key die entsprechenden Optionen zurückgibt
 */
export const optionsDataByTypeInit = (competenceData, fieldData) => (key, filterValue, filterBy) => {
    const type = businessobject_data.types[key]
    key = type ? (type.dataLocation ? type.dataLocation : type.dataAttribute ? type.dataAttribute : key) : key
    if (competenceData && competenceData[key]) {
        if (filterBy) {
            return objectList2options(optionsFilter(optionsFilter(competenceData[key], 'status', data.status.FREIGABE_QUALITAETSSICHERUNG), filterBy, filterValue), 'titel', type.zo ? (o) => ({...defaultEncoder(businessobject_data[key].zoDefault), titel: o.titel, [type.idProperty ? type.idProperty : key + 'Id']: o.id}) : null)
        } else {
            return objectList2options(
                optionsFilter(key === data.businessObject.competence || key === data.businessObject.qualification ? (!filterValue ? flattenObject2Array(competenceData[key]) : competenceData[key][filterValue]) : competenceData[filterValue ? filterValue : key], 'status', data.status.FREIGABE_QUALITAETSSICHERUNG),
                'titel',
                type.zo ? (o) => ({...defaultEncoder(businessobject_data[key].zoDefault), titel: o.titel, [type.idProperty ? type.idProperty : key + 'id']: o.id, ...(type.sortProperty ? {[type.sortProperty]: o[type.sortProperty]} : {})}) : null
            )
        }
    } else if (fieldData && fieldData[key]) {
        return object2options(fieldData[key])
    } else {
        return []
    }
}

export function completeCompetenceAfterUpdate(setValue, values, competenceData, competenceLocation, presentationAttributes, identifier, useDefaultAsFiller, overwrite) {
    // console.log("### completeCompetenceAfterUpdate: ", values, competenceData, competenceLocation, presentationAttributes, identifier, useDefaultAsFiller, overwrite)
    for (let i in values) {
        if (competenceData && !Object.keys(values[i]).some((p) => presentationAttributes.includes(p) && !!values[i][p]) && Object.keys(values[i]).includes(identifier) && typeof values[i][identifier] === 'number') {
            let temp = values[i]
            let search = (Array.isArray(competenceData[competenceLocation]) ? competenceData[competenceLocation] : flattenObject2Array(competenceData[competenceLocation])).find((o) => o.id === temp[identifier])
            if (search) {
                if (useDefaultAsFiller) {
                    if (overwrite) {
                        temp = {}
                    }
                    for (let p of Object.keys(businessobject_data[competenceLocation].default)) {
                        if (search[p]) {
                            temp[p] = search[p]
                        }
                    }
                }
                for (let p of presentationAttributes) {
                    if (overwrite) {
                        temp[p] = search.id
                    } else {
                        temp[p] = search[p] ? search[p] : search.id
                    }
                }
                if (overwrite) {
                    temp[identifier] = search.id
                }
                values[i] = temp
                // console.log("### completeCompetenceAfterUpdate result: ", values)
                setValue(values)
            }
        }
    }
}

/**
 * Erzeugt eine Liste der Keys für die Optionen generiert werden müssen
 *
 * @param {String} key - key für den die Keys gesucht werden müssen
 * @returns Liste von keys für die Optionen benötigt werden
 */
export function findKeys4options(key) {
    let keys4options = []
    if (businessobject_data.types[key] && businessobject_data.types[key].attributeGroup && key != 'beraterrolle') {
        key = businessobject_data.types[key].attributeGroup
    }
    if (businessobject_data[key]) {
        let defaultObjKeys = businessobject_data[key].zoDefault ? [...Object.keys(businessobject_data[key].zoDefault), ...(businessobject_data[key].default ? Object.keys(businessobject_data[key].default) : [])] : businessobject_data[key].childDefault ? [...Object.keys(businessobject_data[key].childDefault), ...(businessobject_data[key].default ? Object.keys(businessobject_data[key].default) : [])] : [...Object.keys(businessobject_data[key].default)]
        for (let k of defaultObjKeys) {
            if (businessobject_data.types[k] && (businessobject_data.types[k].type === data.inputTypes.advanced.DROPDOWN || businessobject_data.types[k].type === data.inputTypes.advanced.FILE)) {
                keys4options.push(k)
            }
        }
    }
    return keys4options
}

/**
 * Sucht für einen key alle benötigten Keys und fasst die entsprechenden Optionen in einem Object
 *
 * @param {Function} optionsDataByType - Funktion zum generieren von Optionen für einen Key
 * @returns eine Funktion die für einen key alle benötigten Keys sucht und die entsprechenden Optionen in einem Object zurückgibt
 */
export const getOptionsInit = (optionsDataByType) => (key) => {
    key = businessobject_data.types[key] && businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key
    let options = {}
    let keys4options = findKeys4options(key)
    for (let k of keys4options) {
        options[k] = optionsDataByType(k)
    }
    return options
}

/**
 * Schaut für ein Array ob Defaultwerte, auch Objekte, enthalten sind und entfernt diese
 *
 * @param {Array} array - Array das es zu Filtern gilt
 * @param {String} key - key der Defaultwerte
 * @param {String} businessObject - businessObjekt der Values
 * @returns Array ohne Defaultwerte
 */
export function arrayDefaultFilter(array, key, businessObject) {
    let tempValues = []
    for (let i in array) {
        if (typeof array[i] === 'object') {
            let tempObj = {}
            key = businessobject_data.types[key] && businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key
            let tempDefault = businessobject_data[key]
            if (!tempDefault.default) {
                tempDefault = tempDefault.zoDefault ? tempDefault.zoDefault : tempDefault.childDefault
            } else {
                tempDefault = tempDefault.default
            }
            for (let tV of Object.keys(array[i])) {
                if ((!!array[i][tV] && !equals(array[i][tV], tempDefault[tV])) || (businessobject_data.types[tV] && ((typeof businessobject_data.types[tV].notnull === 'boolean' && businessobject_data.types[tV].notnull) || (Array.isArray(businessobject_data.types[tV].notnull) && businessobject_data.types[tV].notnull.includes(businessObject))))) {
                    tempObj = {...tempObj, [tV]: array[i][tV]}
                }
            }
            if (Object.keys(tempObj).length > 0) {
                tempValues.push(tempObj)
            }
        } else {
            let tempDefaultObject = (businessobject_data.types[businessObject] && businessobject_data.types[businessObject].attributeGroup ? businessobject_data[businessobject_data.types[businessObject].attributeGroup].childDefault : businessobject_data[businessObject].default)
            if (array[i] && tempDefaultObject && tempDefaultObject[key] && !equals(array[i], tempDefaultObject[key][i])) {
                tempValues.push(array[i])
            }
        }
    }
    return tempValues
}

/**
 * Überprüft ob Werte in einem Array vom Default des Businessobjektes abweichen
 *
 * @param {String} accessStatus - Status des zu bearbeitenden oder zu erstellenden Objektes
 * @param {*} values - Werte des Objektes in einem Array oder Objekt
 * @param {Object} object - bei Update das original Objekt vor bearbeitung
 * @param {String} businessObject - businessObjekt der Values
 * @returns boolean ob die Werte vom Default oder vorherigen abweichen
 */
export function somethingChanges(accessStatus, values, object, businessObject) {
    if (accessStatus === data.accessStatus.create) {
        if (!values) {
            return false
        }
        if (Array.isArray(values)) {
            for (let v of values) {
                for (let t of Object.keys(v)) {
                    if (!equals(v[t], businessobject_data[businessObject][businessobject_data.types[businessObject] && businessobject_data.types[businessObject].attributeGroupParent ? 'childDefault' : 'default'][t]) && t !== 'attributeType') {
                        return true
                    }
                }
            }
        } else {
            for (let t of Object.keys(values)) {
                if (Array.isArray(values[t])) {
                    for (let i in values[t]) {
                        if (typeof values[t][i] === 'object') {
                            for (let tV of Object.keys(values[t][i])) {
                                let tempDefault = businessobject_data[businessobject_data.types[t] && businessobject_data.types[t].dataAttribute ? businessobject_data.types[t].dataAttribute : t]
                                tempDefault = {...tempDefault.default, ...tempDefault.zoDefault, ...tempDefault.childDefault}
                                // if (!tempDefault.default){tempDefault = (tempDefault.zoDefault ? tempDefault.zoDefault : tempDefault.childDefault)}else{tempDefault = tempDefault.default}
                                if (!equals(values[t][i][tV], tempDefault[tV])) {
                                    return true
                                }
                            }
                        } else if (!equals(values[t][i], businessobject_data[businessObject].default[t][i]) && values[t][i]) {
                            return true
                        }
                    }
                } else if (!equals(values[t], businessobject_data[businessObject].default[t])) {
                    return true
                }
            }
        }
    }
    if (accessStatus === data.accessStatus.update) {
        if (!object) {
            return false
        }
        for (let t of Object.keys(values)) {
            if (!equals(values[t], object[t])) {
                return true
            }
        }
    }
}

/**
 * Überprüft ob der Wert zum key nur gelesen werden darf
 *
 * @param {String} key - key des wertes zur Überprüfung
 * @param {boolean} readOnly - globale readOnly Variable
 * @param {String} accessStatus - Status des zu bearbeitenden oder zu erstellenden Objektes
 * @returns boolean ob der Wert zum key nur gelesen werden darf
 */
export function checkReadOnly(key, readOnly, accessStatus) {
    if (businessobject_data.types[key]) {
        if (businessobject_data.types[key].readOnly) {
            return true
        }
        if (businessobject_data.types[key].notUpdateable && accessStatus === data.accessStatus.update) {
            return true
        }
    }
    return readOnly
}

/**
 * Überprüft ob alle Werte valide sind
 * Überprüft dabei notnull, semantic und regex
 *
 * @param {Function} setError - Funktion zum setzen einer Errormessage sollte ein Wert nicht valide sein
 * @param {Object} values - alle Werte eines Objektes zur Überprüfung
 * @param {Object} failedValidation - Objekt dass den validierungs status einer Variable speichert
 * @param {Funktion} setFailedValidation - Funktion zum setzen des failedValidation Objektes
 * @returns boolean ob alle Werte Valide sind
 */
export function validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure) {
    setError('')
    let tempNotNull = false
    let tempFailedValidationNotNull = {} //{...failedValidation.notnull}
    // console.log("### validateValues ", setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure, tempFailedValidationNotNull, tempNotNull)
    for (let tab of setTabOfFailure ? Object.keys(businessobject_data[businessObject].fields) : ['noTab']) {
        let fieldkeys = []
        if (setTabOfFailure) {
            let fields = {...businessobject_data[businessObject].fields[tab]}
            for (let fk of Object.keys(fields)) {
                if (fk.includes('topic')){
                    for (let ftk of Object.keys(fields[fk])) {
                        if (ftk.includes('group')) {
                            for (let fgk of Object.keys(fields[fk][ftk])) {
                                fieldkeys.push(fgk)
                            }
                        } else {
                            fieldkeys.push(ftk)
                        }
                    }
                }
                if (fk.includes('group')) {
                    for (let fgk of Object.keys(fields[fk])) {
                        fieldkeys.push(fgk)
                    }
                } else {
                    fieldkeys.push(fk)
                }
            }
        }
        for (let key of setTabOfFailure ? fieldkeys : Object.keys(values)) {
            if (Array.isArray(values[key])) {
                for (let i in values[key]) {
                    if (typeof values[key][i] === 'object') {
                        for (let tK of Object.keys(values[key][i])) {
                            // ((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))
                            // console.log("### validateValues iteration ", key, i, tK, businessobject_data.types[tK], businessobject_data.types[tK].notnull, values[key][i][tK])
                            if (businessobject_data.types[tK] && ((typeof businessobject_data.types[tK].notnull === 'boolean' && businessobject_data.types[tK].notnull) || (Array.isArray(businessobject_data.types[tK].notnull) && businessobject_data.types[tK].notnull.includes(businessObject))) && typeof values[key][i][tK] !== 'boolean' && !values[key][i][tK]) {
                                tempFailedValidationNotNull = {...tempFailedValidationNotNull, [key]: {...tempFailedValidationNotNull[key], [i]: {...(tempFailedValidationNotNull[key] ? tempFailedValidationNotNull[key][i] : null), [tK]: true}}}
                                tempNotNull = true
                            }
                        }
                    }
                }
            } else if (values[key] && typeof values[key] === 'object' && businessobject_data[key] && businessobject_data[key].default && !equals(values[key], businessobject_data[key].default)) {
                for (let tK of Object.keys(values[key])) {
                    // console.log("### validateValues iteration 2 ", key, tK, businessobject_data.types[tK], businessobject_data.types[tK].notnull, values[key][tK])
                    if (businessobject_data.types[tK] && ((typeof businessobject_data.types[tK].notnull === 'boolean' && businessobject_data.types[tK].notnull) || (Array.isArray(businessobject_data.types[tK].notnull) && businessobject_data.types[tK].notnull.includes(businessObject))) && typeof values[key][tK] !== 'boolean' && !values[key][tK]) {
                        tempFailedValidationNotNull = {...tempFailedValidationNotNull, [key]: {...tempFailedValidationNotNull[key], [tK]: true}}
                        tempNotNull = true
                    }
                }
            } else if (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))) && typeof values[key] !== 'boolean' && (!values[key] || (Array.isArray(values[key]) && values[key].length === 0))) {
                // console.log("### validateValues iteration 3 ", key, businessobject_data.types[key], businessobject_data.types[key].notnull, values[key])
                tempFailedValidationNotNull = {...tempFailedValidationNotNull, [key]: true}
                tempNotNull = true
            }
            //überprüft Pflichtfelder für mehrere Einträge (z.B. Kompetenzen)#
            //!businessObject benötigt, damit beispielsweise in Berater nicht zwingend ein Name für Profilbild verlangt wird, falls dieses gar nicht hochgeladen wird
            else if (values[key] && typeof values[key] === 'object' && !businessObject) {
                for (let subKey of Object.keys(values[key])) {
                    if (businessobject_data.types[subKey] && businessobject_data.types[subKey].notnull) {
                        // console.log("### validateValues iteration 4 ", key, subKey, businessobject_data.types[subKey], businessobject_data.types[subKey].notnull, values[key][subKey])
                        if (values[key][subKey] === 0 || values[key][subKey] === null || values[key][subKey] === '') {
                            tempFailedValidationNotNull = {...tempFailedValidationNotNull, [key]: true}
                            tempNotNull = true
                        } else if (tempFailedValidationNotNull[key] && tempNotNull === false) {
                            tempFailedValidationNotNull = {...tempFailedValidationNotNull, [key]: false}
                        }
                    }
                }
            }
        }
        // console.log("### validateValues ", tempFailedValidationNotNull, tempNotNull)
        setFailedValidation({...failedValidation, notnull: {...tempFailedValidationNotNull}})
        if (tempNotNull) {
            setError(message_data.error.pflegen.notnull)
            if (setTabOfFailure) {
                setTabOfFailure(tab)
            }
            return false
        }
        for (let key of setTabOfFailure ? fieldkeys : Object.keys(tempFailedValidationNotNull)) {
            if (tempFailedValidationNotNull[key] && (typeof tempFailedValidationNotNull[key] === 'boolean' || objectHasValue(tempFailedValidationNotNull[key], true))) {
                // console.log("### validateValues, not validated ", tempFailedValidationNotNull, tempNotNull, key)
                setError(message_data.error.pflegen.notnull)
                if (setTabOfFailure) {
                    setTabOfFailure(tab)
                }
                return false
            }
        }
        for (let key of setTabOfFailure ? fieldkeys : Object.keys(failedValidation.semantic)) {
            if (failedValidation.semantic[key] && (typeof failedValidation.semantic[key] === 'boolean' || objectHasValue(failedValidation.semantic[key], true))) {
                setError(message_data.error.pflegen.semantic)
                if (setTabOfFailure) {
                    setTabOfFailure(tab)
                }
                return false
            }
        }
        for (let key of setTabOfFailure ? fieldkeys : Object.keys(failedValidation.regex)) {
            if (failedValidation.regex[key] && (typeof failedValidation.regex[key] === 'boolean' || objectHasValue(failedValidation.regex[key], true))) {
                setError(message_data.error.pflegen.regex)
                if (setTabOfFailure) {
                    setTabOfFailure(tab)
                }
                return false
            }
        }
    }
    // console.log("### validateValues, validated ", tempFailedValidationNotNull, tempNotNull)
    return true
}

function objectHasValue(obj, val) {
    for (let key of Object.keys(obj)) {
        if (typeof obj[key] === 'object') {
            if (objectHasValue(obj[key], val)) {
                return true
            }
        } else {
            if (obj[key] === val) {
                return true
            }
        }
    }
}

/**
 *
 *
 * @param {String} key - dessen Wert überprüft werden soll
 * @param {Object} failedValidation - Objekt dass den validierungs status einer Variable speichert
 * @param {Funktion} setFailedValidation - Funktion zum setzen des failedValidation Objektes
 * @param {*} value - Wert zum Key dass überprüft werden soll
 * @param {*} compare2value - Wert zum Vergleichen bei semantischer Validierung
 * @returns boolean ob der Wert zum Key valide ist oder Objekt mit allen invaliden Werten bei einem Objekt als Wert
 */
export function checkInvalidation(key, failedValidation, setFailedValidation, value, compare2value) {
    if (failedValidation.notnull[key] && ((!!value && typeof value !== 'object') || (Array.isArray(value) && value.length > 0))) {
        setFailedValidation({...failedValidation, notnull: {...failedValidation.notnull, [key]: false}})
    }
    if (businessobject_data.types[key] || businessobject_data[key]) {
        if ((businessobject_data[key] && (businessobject_data[key].default || businessobject_data[key].childDefault) && (businessobject_data.types[key] ? businessobject_data.types[key].type !== data.inputTypes.advanced.OBJECTFK : true)) || businessobject_data.types[key].type === data.inputTypes.advanced.INLINEDEFINE || businessobject_data.types[key].type === data.inputTypes.advanced.ROWDEFINE) {
            let invalidObj = {}
            if (!Array.isArray(value)) {
                value = [value]
            }
            for (let i in value) {
                let defaultObj = businessobject_data.types[key] && businessobject_data.types[key].dataAttribute ? businessobject_data[businessobject_data.types[key].dataAttribute] : businessobject_data[key]
                if (!defaultObj.default) {
                    defaultObj = defaultObj.zoDefault ? defaultObj.zoDefault : defaultObj.childDefault
                } else {
                    defaultObj = defaultObj.default
                }
                // console.log("### checkInvalidation, defaultObj: ", defaultObj, value, key, i, failedValidation)
                for (let tK of Object.keys(defaultObj)) {
                    // validate regex
                    if (value[i] && (value[i][tK] || typeof value[i][tK] === 'boolean')) {
                        if (businessobject_data.types[tK] && businessobject_data.types[tK].regex) {
                            let tempValue = typeof value[i][tK] === 'number' ? value[i][tK].toString() : value[i][tK]
                            if (typeof tempValue === 'string') {
                                if (!tempValue.match(businessobject_data.types[tK].regex)) {
                                    // setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [k]:{...failedValidation.regex[k], [i]:{...failedValidation.regex[k][i], [tK]:true}}}})
                                    invalidObj = {...invalidObj, [i]: {...invalidObj[i], [tK]: true}}
                                    if (!failedValidation.regex[key] || !failedValidation.regex[key][i] || !failedValidation.regex[key][i][tK]) {
                                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: {...(failedValidation.regex[key] ? failedValidation.regex[key][i] : {}), [tK]: true}}}})
                                    }
                                } else {
                                    if (failedValidation.regex[key] && failedValidation.regex[key][i] && failedValidation.regex[key][i][tK]) {
                                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: {...failedValidation.regex[key][i], [tK]: false}}}})
                                    }
                                }
                            }
                        }
                        //validate semantic
                        if (businessobject_data.types[tK] && businessobject_data.types[tK].semanticCheck) {
                            if (!semanticCheck(value[i][tK], value[i][businessobject_data.types[tK].semanticCheck.compareTo], businessobject_data.types[tK].semanticCheck.function)) {
                                invalidObj = {...invalidObj, [i]: {...invalidObj[i], [tK]: true}}
                                if (!failedValidation.semantic[key] || !failedValidation.semantic[key][i] || !failedValidation.semantic[key][i][tK]) {
                                    setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: {...failedValidation.semantic[key], [i]: {...(failedValidation.semantic[key] ? failedValidation.semantic[key][i] : {}), [tK]: true}}}})
                                }
                            } else {
                                if (failedValidation.semantic[key] && failedValidation.semantic[key][i] && failedValidation.semantic[key][i][tK]) {
                                    setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: {...failedValidation.semantic[key], [i]: {...failedValidation.semantic[key][i], [tK]: false}}}})
                                }
                            }
                        }
                        //validate Date
                        if (businessobject_data.types[tK] && businessobject_data.types[tK].type === data.inputTypes.standard.DATE && value[i][tK]) {
                            try {
                                const tempValue = new Date(value[i][tK])
                                if (tempValue < new Date('1900') || isNaN(tempValue)) {
                                    invalidObj = {...invalidObj, [i]: {...invalidObj[i], [tK]: true}}

                                    if (!failedValidation.regex[key] || !failedValidation.regex[key][i] || !failedValidation.regex[key][i][tK]) {
                                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: {...(failedValidation.regex[key] ? failedValidation.regex[key][i] : {}), [tK]: true}}}})
                                    }
                                } else {
                                    if (failedValidation.regex[key] && failedValidation.regex[key][i] && failedValidation.regex[key][i][tK]) {
                                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: {...failedValidation.regex[key][i], [tK]: false}}}})
                                    }
                                }
                            } catch {
                                invalidObj = {...invalidObj, [i]: {...invalidObj[i], [tK]: true}}
                                if (!failedValidation.regex[key] || !failedValidation.regex[key][i] || !failedValidation.regex[key][i][tK]) {
                                    setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: {...(failedValidation.regex[key] ? failedValidation.regex[key][i] : {}), [tK]: true}}}})
                                }
                            }
                        }
                        // else {
                        //     if (failedValidation.semantic[key] && failedValidation.semantic[key][i] && failedValidation.semantic[key][i][tK]){
                        //         setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]:{...failedValidation.semantic[key], [i]:{...failedValidation.semantic[key][i], [tK]:false}}}})
                        //     }
                        //     if (failedValidation.regex[key] && failedValidation.regex[key][i] && failedValidation.regex[key][i][tK]){
                        //         setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]:{...failedValidation.regex[key], [i]:{...failedValidation.regex[key][i], [tK]:false}}}})
                        //     }
                        // }
                        // console.log("### failedValidation: ", failedValidation, key, i, tK)
                        if (failedValidation.notnull[key] && failedValidation.notnull[key][i] && failedValidation.notnull[key][i][tK]) {
                            if ((!!value[i][tK] && typeof value[i][tK] !== 'object') || (Array.isArray(value[i][tK]) && value[i][tK].length > 0)) {
                                setFailedValidation({...failedValidation, notnull: {...failedValidation.notnull, [key]: {...failedValidation.notnull[key], [i]: {...failedValidation.notnull[key][i], [tK]: false}}}})
                            } else {
                                invalidObj = {...invalidObj, [i]: {...invalidObj[i], [tK]: true}}
                            }
                        }
                    }
                }
            }
            if (failedValidation.semantic[key] && Object.keys(failedValidation.semantic[key]).length > value.length) {
                let tempFailedVaildationSemantic = {...failedValidation.semantic[key]}
                let diff = Object.keys(failedValidation.semantic[key]).length - value.length
                while (diff > 0) {
                    delete tempFailedVaildationSemantic[value.length - 1 + diff]
                    diff--
                }
                setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: {...tempFailedVaildationSemantic}}})
            }
            if (failedValidation.regex[key] && Object.keys(failedValidation.regex[key]).length > value.length) {
                let tempFailedVaildationRegex = {...failedValidation.regex[key]}
                let diff = Object.keys(failedValidation.regex[key]).length - value.length
                while (diff > 0) {
                    delete tempFailedVaildationRegex[value.length - 1 + diff]
                    diff--
                }
                setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...tempFailedVaildationRegex}}})
            }
            // setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [k]:invalidObj}})
            // console.log("### invalidObj: ", invalidObj)
            return invalidObj
        }
        // validate regex
        if (businessobject_data.types[key] && businessobject_data.types[key].regex) {
            if (businessobject_data.types[key].type === data.inputTypes.standard.MULTIPLE) {
                let invalidObj = {}
                for (let i in value) {
                    if (value[i] && !value[i].match(businessobject_data.types[key].regex)) {
                        invalidObj = {...invalidObj, [i]: true}
                        if (!failedValidation.regex[key] || !failedValidation.regex[key][i]) {
                            setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: true}}})
                        }
                    } else {
                        invalidObj = {...invalidObj, [i]: false}
                        if (failedValidation.regex[key] && failedValidation.regex[key][i]) {
                            setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: {...failedValidation.regex[key], [i]: false}}})
                        }
                    }
                }
                // setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [k]:invalidObj}})
                return invalidObj
            } else {
                if (value && !value.match(businessobject_data.types[key].regex)) {
                    if (!failedValidation.regex[key]) {
                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: true}})
                    }
                    return true
                } else if (failedValidation.regex[key]) {
                    setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: false}})
                }
            }
        }
        //validate semantic
        if (businessobject_data.types[key] && businessobject_data.types[key].semanticCheck) {
            if (businessobject_data.types[key].type !== data.inputTypes.standard.MULTIPLE) {
                if (value && !semanticCheck(value, compare2value, businessobject_data.types[key].semanticCheck.function)) {
                    if (!failedValidation.semantic[key]) {
                        setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: true}})
                    }
                    return true
                } else if (failedValidation.semantic[key]) {
                    setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: false}})
                }
            }
        }
        //validate Date
        if (businessobject_data.types[key] && businessobject_data.types[key].type === data.inputTypes.standard.DATE && value) {
            try {
                const tempValue = new Date(value)
                if (tempValue < new Date('1900') || isNaN(tempValue)) {
                    if (!failedValidation.regex[key]) {
                        setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: true}})
                    }
                    return true
                } else if (failedValidation.regex[key]) {
                    setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: false}})
                }
            } catch {
                if (!failedValidation.regex[key]) {
                    setFailedValidation({...failedValidation, regex: {...failedValidation.regex, [key]: true}})
                }
                return true
            }
        }
        if (businessobject_data.types[key] && businessobject_data.types[key].type === data.inputTypes.standard.MULTIPLE && value) {
            let invalidObj = {}
            for (let i in value) {
                if (value.indexOf(value[i]) != i) {
                    invalidObj = {...invalidObj, [i]: true}
                    if (!failedValidation.semantic[key] || !failedValidation.semantic[key][i]) {
                        setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: {...failedValidation.semantic[key], [i]: true}}})
                    }
                } else {
                    invalidObj = {...invalidObj, [i]: false}
                    if (failedValidation.semantic[key] && failedValidation.semantic[key][i]) {
                        setFailedValidation({...failedValidation, semantic: {...failedValidation.semantic, [key]: {...failedValidation.semantic[key], [i]: false}}})
                    }
                }
            }
            return invalidObj
        }
    }

    return failedValidation.notnull[key]
}

export function searchObject(obj, key) {
    if (obj[key] && typeof obj[key] !== 'object') {
        return obj[key]
    } else {
        for (let k in obj) {
            if (typeof obj[k] === 'object') {
                let res = searchObject(obj[k], key)
                if (res) {
                    return res
                }
            }
        }
    }
}

export function projectduration(project) {
    let duration = parseFloat((((project.enddatum ? new Date(project.enddatum) : new Date()) - (project.startdatum ? new Date(project.startdatum) : new Date())) / 1000 / 60 / 60 / 24 / 30).toFixed(1))
    if (project.projektunterbrechungen?.length > 0) {
        for (let pu of project.projektunterbrechungen) {
            duration -= parseFloat((((pu.enddatum ? new Date(pu.enddatum) : new Date()) - (pu.startdatum ? new Date(pu.startdatum) : new Date())) / 1000 / 60 / 60 / 24 / 30).toFixed(1))
        }
    }
    return duration
}

export function sortList (sortKey, sortASC, pList, decode2presentation) {

    let list = [...pList]
    if (sortKey) {
        //evtl passt die comparator Funktion nicht ganz, vielleicht werden edge cases nicht abgefangen
        list.sort(function (a, b) {
            // return ((a[sort.value]&&b[sort.value]) ? (typeof a[sort.value] === 'string' ? (a[sort.value].toLowerCase().localeCompare(b[sort.value].toLowerCase()) === 0 ? ((a.titel && b.titel) ? a.titel.toLowerCase().localeCompare(b.titel.toLowerCase()) : 0) : a[sort.value].toLowerCase().localeCompare(b[sort.value].toLowerCase())) : (typeof a[sort.value] === 'number' ? a[sort.value] <= b[sort.value] : false)):false)
            // Überprüfe, ob die Werte vorhanden sind, und setze sie gegebenenfalls auf leere Strings
            var aValue = a[sortKey] !== undefined && a[sortKey] !== null ? a[sortKey] : ''
            var bValue = b[sortKey] !== undefined && b[sortKey] !== null ? b[sortKey] : ''

            // Wenn ein Wert leer ist, platziere das leere Element am Ende der Liste
            if (aValue === '' && bValue !== '') {
                return 1 // b soll vor a stehen
            } else if (aValue !== '' && bValue === '') {
                return -1 // a soll vor b stehen
            }

            // Sortierung nach Strings
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) === 0
                    ? // Wenn die Strings gleich sind, sortiere nach dem zweiten Schlüssel
                      a.titel && b.titel
                        ? a.titel.toLowerCase().localeCompare(b.titel.toLowerCase())
                        : 0
                    : aValue.toLowerCase().localeCompare(bValue.toLowerCase())
            }
            // Sortierung nach Zahlen
            else if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
                if (decode2presentation && !businessobject_data.displayList[sortKey] && sortKey.includes('Id')) {
                    return decode2presentation(sortKey, aValue, a)?.toLowerCase().localeCompare(decode2presentation(sortKey, bValue, b)?.toLowerCase())
                } else if (decode2presentation && businessobject_data.displayList[sortKey]) {
                    return decode2presentation(sortKey, a).toLowerCase().localeCompare(decode2presentation(sortKey, b).toLowerCase())
                }
                return parseFloat(aValue) <= parseFloat(bValue)
            }
            // Wenn die Werte nicht vergleichbar sind, belasse sie in ihrer aktuellen Reihenfolge
            return false
        })
    }
    if (!sortASC) {
        list.reverse()
        //moveEmptyToEnd
        list.sort(function (a, b) {
            if (a[sortKey] === '' && b[sortKey] !== '') {
                return 1 // b soll vor a stehen
            } else if (a[sortKey] !== '' && b[sortKey] === '') {
                return -1 // a soll vor b stehen
            }
            return false
        })
    }
    return list
}