import React, {useState, useEffect} from 'react'

import data from '../../data/_data'
import businessobject_data from '../../data/businessobject_data';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { TooltipInfo } from '../_helpers/InnerContent';
import StatusCycle from '../_helpers/StatusCycle';


/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {String} businessObject - aktuell gewaehltes Geschaeftsobjekt
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {Function} onSelect - Funktion zum setzen des ausgewählten Objektes
 * @param {String} onSelectCategory - Key des CategoryFilter bei onSelect
 * @param {Function} setLockedState - Funktion zum setzen von locked
 * @param {Function} setSearchAttrState - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchValState - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilterState - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setBooleanFilterState - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchCompetenceAttrState - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchCompetenceValState - Funktion zum setzen des Wertes der Kompetenz, passen dzum Attribut, zum Filtern in der Suche
 * @param {Function} setSearchStartDateState - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDateState - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilterState - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {String} categoryFilterState - Key des CategoryFilter
 * @see Pflegen 
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function PflegenKopfzeile_Gefuehrt({role, accessStatus, businessObject, object, fkLists, businessObjectFunctions, successMsg, loggedInUsername, guidedProgessState, setGuidedProgessState, maxguidedProgess, consultantObject, consultantprojectObject}){

    const getSumAttrPresentation4Consultantproject = (attr) => {
        if (businessobject_data.displayList[attr]){
            if (attr === 'kundenprojektId_kundeId'){
                    const fkKundeId = (fkLists && fkLists.customerprojects && fkLists.customerprojects.find(obj => obj.id === consultantprojectObject.kundenprojektId) ? fkLists.customerprojects.find(obj => obj.id === consultantprojectObject.kundenprojektId).kundeId  : null)
                    return (fkKundeId && fkLists && fkLists.customers && fkLists.customers.find(obj => obj.id === fkKundeId) ? fkLists.customers.find(obj => obj.id === fkKundeId).name : '')
            }
        } else {
            return (object[attr.substring(0,attr.indexOf("_"))]+', '+object[attr.substring(attr.indexOf("_")+1)])
        }
    }

    const ProgessPercent = (guidedProgessState ? ((guidedProgessState-1)*(100/(maxguidedProgess-1))+((100/(maxguidedProgess-1)))-0.000000001) : 0)
    return (
        <div className="content-header" id="guided">
            {(consultantObject && guidedProgessState <= 1) && <StatusCycle isPM={true} status={consultantObject.status} accessStatus={accessStatus} businessObject={data.businessObject.consultant} role={role} />}
            {(consultantprojectObject && guidedProgessState > 2 && guidedProgessState < 100) && <StatusCycle isPM={true} status={consultantprojectObject.status} accessStatus={accessStatus} businessObject={data.businessObject.consultantproject} role={role} />}
            {guidedProgessState>0 && <button id="main" onClick={businessObjectFunctions.guidedPrev}>Zurück</button>}
            <div className='progress-bar'>
                <div className='progess-bar-label'>
                    <div className='progess-label' style={{width: maxguidedProgess*2.5+'vw'}}>Berater</div>
                    <div className='progess-label' style={{marginLeft: '10px', width: maxguidedProgess*5.5+'vw'}}>Projekte</div>
                </div>
                <ProgressBar percent={(ProgessPercent>100 ? 100 : ProgessPercent)}>
                {[...Array(maxguidedProgess).keys()].map((val, index) => {
                    val = val+1
                    return (
                        <Step key={index} >
                            {() => (
                                <div className={"progress-bar-index" + ((((val-1)*(100/(maxguidedProgess-1))) < ProgessPercent) ? ' accomplished' : '') + ((val-1) == guidedProgessState ? ' progress-bar-current' : '')} style={((val == 3 || val == 1) ? {cursor: 'pointer'} : null)} onClick={() => {if (val == 3){setGuidedProgessState(2);}if (val == 1){setGuidedProgessState(0)}}} >
                                    {val}
                                </div>
                            )}
                        </Step>
                    )
                })}
                </ProgressBar>
            </div>
            {(consultantObject && (guidedProgessState <= 1)) && <div className="sum-attributes flat">
                {Object.keys(businessobject_data.berater.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.berater.sumAttributes[attr]}<input disabled value={(consultantObject && Object.keys(consultantObject).length > 0) ? (attr.includes("_") ? (consultantObject[attr.substring(0,attr.indexOf("_"))]+', '+consultantObject[attr.substring(attr.indexOf("_")+1)])  : consultantObject[attr]) : ''} /></div>))}
            </div>}
            {(consultantprojectObject && (guidedProgessState > 2 && guidedProgessState <= 5)) && <div className="sum-attributes flat">
                {Object.keys(businessobject_data.beraterprojekt.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.beraterprojekt.sumAttributes[attr]}<input disabled value={(consultantprojectObject && Object.keys(consultantprojectObject).length > 0) ? (attr.includes("_") ? getSumAttrPresentation4Consultantproject(attr)  : consultantprojectObject[attr]) : ''} /></div>))}
            </div>}
        </div>
    )
}

export default PflegenKopfzeile_Gefuehrt