import React, {useEffect} from 'react'
import businessobject_data from '../../../data/businessobject_data'

import { DateSearch, HeaderButtons, MultiSelect, Tabs } from '../../_helpers/Kopfzeile'
import data from '../../../data/_data'
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import { objectList2AttrList, optionsFilter } from './../../_helpers/functions';
import { SingleSelect } from './../../_helpers/Kopfzeile';
import { TooltipInfo } from '../../_helpers/InnerContent';
import StatusCycle from '../../_helpers/StatusCycle';


/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {boolean} somethingChanges - gibt an, ob sich das Object geändert hat
 * @param {Object} businessObject - die Art des Gechäftsobjektes, hier Ansprechpartner oder Kunde
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {Function} setLocked - Funktion zum setzen des Status GESPERRT in der Suche
 * @param {Function} setSearchAttr - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchVal - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilter - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchFKAttr - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchStartDate - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDate - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilterState - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {Function} onFilter - Funktion zum Anwenden der gesetzten Filter in der Parent Komponente
 * @param {Function} onFilterReset - Funktion zum Zurücksetzen der Filter in der Parent Komponente
 * @Param {String} searchAttr - Filterparameter: Attribut der Objekte auf das sich das searchVal bezieht
 * @Param {String} searchVal -  Filterparameter: Value des Attributes der Objekte auf das sich searchAttr bezieht
 * @param {String} locked - Filterparameter: Gibt an, ob die Objekter den Status GESPERRT haben müssen
 * @Param {String} statusFilter - Filterparameter: Value des Status der Objekte
 * @Param {String} searchStartDate - Filterparameter: Startdatum der Objekte
 * @Param {String} searchEndDate - Filterparameter: Enddatum der Objekte
 * @see Pflegen 
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function PflegenKopfzeile_Beraterprojekt({role, id, accessStatus, object, readOnly, fkLists, somethingChanges, businessObjectFunctions, onSelect, onSelectPrefiltered, setLocked, locked, setSearchAttr, setSearchVal, searchAttr, searchVal, statusFilter, setStatusFilter, booleanFilter, setBooleanFilter, setTab, tabs, tab, successMsg, loggedInUsername, competenceFilterData, setSearchFKAttr, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset, setWantedStatus}){
    var options = []
    for (var value of (onSelectPrefiltered ? Object.keys(onSelectPrefiltered.extendOptions) : Object.keys(businessobject_data.beraterprojekt.searchAttributes))){
        options.push({value: value, label: (onSelectPrefiltered ? onSelectPrefiltered.extendOptions : businessobject_data.beraterprojekt.searchAttributes)[value]})
    }// [{value: '',label: ''}]
    let history = useHistory();

    useEffect(() => {if (accessStatus === data.accessStatus.search && !searchAttr){setSearchAttr(options[0].value)}})

    const getSumAttrPresentation = (attr) => {
        if (businessobject_data.displayList[attr]){
            if (attr === 'kundenprojektId_kundeId'){
                    const fkKundeId = (fkLists && fkLists.customerprojects && fkLists.customerprojects.find(obj => obj.id === object.kundenprojektId) ? fkLists.customerprojects.find(obj => obj.id === object.kundenprojektId).kundeId  : null)
                    return (fkKundeId && fkLists && fkLists.customers && fkLists.customers.find(obj => obj.id === fkKundeId) ? fkLists.customers.find(obj => obj.id === fkKundeId).name : '')
            }
        } else {
            return (object[attr.substring(0,attr.indexOf("_"))]+', '+object[attr.substring(attr.indexOf("_")+1)])
        }
    }

    const getMainFunction = () => {
        if (accessStatus === data.accessStatus.update){
            if (role === data.roles.MITARBEITER){
                return {function: businessObjectFunctions.onSavePMPrivate, value: 'Änderungen Speichern', tooltip: "Änderungen speichern und Status nicht verändern"}
            } else {
                if (object && object.status === data.status.FREIGABE_MITARBEITER){
                    return {function: businessObjectFunctions.onRelease, value: 'Änderungen Übernehmen & Freigeben', tooltip: "Änderungen des Mitarbeiters in Freigabe Qualitätssicherung übernehmen"}
                }
                return {function: businessObjectFunctions.onSave, value: 'Änderungen Speichern', tooltip: "Änderungen speichern und Status nicht verändern" }
            }
        }
    }
    const getSideMainFunction = () => {
        if (accessStatus === data.accessStatus.update){
            if (role === data.roles.MITARBEITER && !readOnly){
                return {function: businessObjectFunctions.onSavePM, value: 'Änderungen Speichern & Freigeben', tooltip: "Änderungen speichern und zur Qualiätssicherung freigeben"}
            } else {
                if (object && object.status === data.status.FREIGABE_MITARBEITER){
                    return {function: businessObjectFunctions.onSavePMPrivate, value: 'Änderungen nicht übernehmen', tooltip: "Änderungen des Mitarbeiters nicht freigeben und dem Mitarbeiter zurückgeben"}
                }
                if (object && object.status === data.status.BEARBEITUNG && object.berater === loggedInUsername) {
                    return {function: businessObjectFunctions.onSavePM, value: 'Änderungen Übernehmen & Freigeben', disabled: readOnly, tooltip: 'Änderungen speichern und zur Qualiätssicherung freigeben'}
                }
                return null
            }
        }
    }
    
    if (accessStatus === data.accessStatus.search){
        return(
            <div className="content-header" id="consultantproject-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Beraterprojekt</div>
        
                    <div className="fast-search">
                        {!onSelectPrefiltered && <button className={"micro"+((searchAttr === 'berater' && searchVal === loggedInUsername) ? ' clicked' : '')} onClick={() => {setSearchAttr('berater'); setSearchVal(loggedInUsername); setSearchEndDate(-1); onFilter('berater', loggedInUsername, -1)}}>Nur eigene</button>}
                    </div>
                    {!onSelect && role === data.roles.BECKOFFICE && <MultiSelect cssId="statusFilter" filterState={statusFilter} setFilterState={setStatusFilter} selection={{GESPERRT: 'Gesperrt', FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'}/*data.presentation.status*/} />}
                    <MultiSelect cssId="booleanFilter" filterState={booleanFilter} setFilterState={setBooleanFilter} selection={data.projectStatus} />
                    

                    <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate} />


                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} value={optionsFilter(options, 'value', searchAttr)} isSearchable={false} defaultValue={options[0]} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchAttr(s.value)}/>
                        {searchAttr==='kompetenzen' && <Select className="dropdown" classNamePrefix="react-select" name="SearchFKAttr" options={competenceFilterData} isSearchable={false} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchFKAttr(s.value)}/> }
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>
                
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )
    } else {

        return(
            <div className="content-header">
                {object && <StatusCycle status={object.status} accessStatus={accessStatus} businessObject={data.businessObject.consultantproject} readOnly={readOnly} role={role} setStatus={setWantedStatus} isPM={object.berater == loggedInUsername} />}
                <Tabs tabs={tabs} activeTab={tab} setTab={setTab} />
                {accessStatus === data.accessStatus.update && <div className="sum-attributes">
                    {Object.keys(businessobject_data.beraterprojekt.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.beraterprojekt.sumAttributes[attr]}<input disabled value={object ? (attr.includes("_") ? getSumAttrPresentation(attr)  : object[attr]) : ''} /></div>))}
                </div>}
                
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function:((role !== data.roles.MITARBEITER) ? businessObjectFunctions.onCreate : businessObjectFunctions.onCreatePM), value: 'Anlegen & Freigeben', tooltip: ('Anlegen und ' + ((role !== data.roles.MITARBEITER) ? 'für andere freigeben' : 'zur Qualitätssicherung freigeben'))}} second={{function:((role !== data.roles.MITARBEITER) ? businessObjectFunctions.onCreateLock : businessObjectFunctions.onCreatePMPrivate), value: 'Anlegen', tooltip: ('Anlegen und ' + ((role !== data.roles.MITARBEITER) ? 'für Mitarbeiter sperren' : 'nicht zur Qualitaetssicherung freigeben'))}} disabled={(!somethingChanges ? true : readOnly)} smalls={[{function:() => history.push('/pflegen/beraterprojekt'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
                {accessStatus === data.accessStatus.update && <HeaderButtons main={{...getMainFunction(), disabled:((!somethingChanges && object?.status !== data.status.FREIGABE_MITARBEITER) ? true : readOnly)}} second={{...getSideMainFunction(), disabled:((!somethingChanges && object?.status !== data.status.FREIGABE_MITARBEITER) ? true : readOnly)}} disabled={readOnly} opposit={{function:() => history.push('/pflegen/beraterprojekt/' + object?.id), value:'Bearbeiten', disabled:(PermissionForbidden(role, loggedInUsername, id, data.businessObject.consultantproject, object)||!object)}} smalls={[{function:businessObjectFunctions.onDelete, value:'Löschen'}, {function:businessObjectFunctions.onLock, value:(object ? (object.status === data.status.GESPERRT ? "Entsperren" : "Sperren") : "Sperren"), disabled:(readOnly||role === data.roles.MITARBEITER)}, {function:() => history.push('/pflegen/beraterprojekt'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
                {/* {function:businessObjectFunctions.onRelease, value:'Freigeben', disabled: (object ? object.status===data.status.FREIGABE_QUALITAETSSICHERUNG || object.status===data.status.GESPERRT : true)}, */}
            </div>
        )
    }
}

export default PflegenKopfzeile_Beraterprojekt