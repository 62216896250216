import React, {useEffect} from 'react'
import businessobject_data from '../../../data/businessobject_data'

import data from '../../../data/_data'
import { DateSearch, HeaderButtons, MultiSelect, Tabs } from '../../_helpers/Kopfzeile'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import { optionsFilter } from './../../_helpers/functions';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import { TooltipInfo } from '../../_helpers/InnerContent';
import StatusCycle from '../../_helpers/StatusCycle'



/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Plegen", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} object - das ausgewaehlte Geschaeftsobjekt
 * @param {boolean} somethingChanges - gibt an, ob sich das Object geändert hat
 * @param {Object} businessObjectFunctions - Objekt als Sammlung der Funktionen zum Pflegen im Parent, dem Subject (e.g. Anlegen)
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {Function} setLocked - Funktion zum setzen des Status GESPERRT in der Suche
 * @param {Function} setSearchAttr - Funktion zum setzen des Attributes der Suche
 * @param {Function} setSearchVal - Funktion zum setzen des Wertes zur Suche
 * @param {Function} setStatusFilter - Funktion zum setzen des Status zum Filtern in der Suche
 * @param {Function} setTab - Funktion zum festlegen des ausgeaehlten Reiters
 * @param {Array} tabs - zur Verfügung stehende Reiter
 * @param {String} tab - ausgewaehlter Reiter
 * @param {String} successMsg - SuccesMsg der API bei Erfolg
 * @param {String} loggedInUsername - Username des aktuell angemeldeten Benutzers
 * @param {Object} competenceFilterData - optionen fürs Dropdown zum auswaehlen zum Filtern
 * @param {Function} setSearchFKAttr - Funktion zum setzen des Attributes der Kompetenz zum Filtern in der Suche
 * @param {Function} setSearchStartDate - Funktion zum setzen des Startdatums zum Filtern in der Suche
 * @param {Function} setSearchEndDate - Funktion zum setzen des Enddatums zum Filtern in der Suche
 * @param {Function} setCategoryFilterState - Funktion zum setzen der Category zum Filtern in der Suche
 * @param {Function} onFilter - Funktion zum Anwenden der gesetzten Filter in der Parent Komponente
 * @param {Function} onFilterReset - Funktion zum Zurücksetzen der Filter in der Parent Komponente
 * @Param {String} searchAttr - Filterparameter: Attribut der Objekte auf das sich das searchVal bezieht
 * @Param {String} searchVal -  Filterparameter: Value des Attributes der Objekte auf das sich searchAttr bezieht
 * @param {String} locked - Filterparameter: Gibt an, ob die Objekter den Status GESPERRT haben müssen
 * @Param {String} statusFilter - Filterparameter: Value des Status der Objekte
 * @Param {String} searchStartDate - Filterparameter: Startdatum der Objekte
 * @Param {String} searchEndDate - Filterparameter: Enddatum der Objekte
 * @see Pflegen 
 * @see Suche als Parent
 * @returns {HTML} Kopfzeile zum Filtern der Suche oder wechseln der Reiter während der Bearbeitung
 */
function PflegenKopfzeile_Kundenprojekt({role, accessStatus, readOnly, object, somethingChanges, onSelect, businessObjectFunctions, setLocked, locked, setSearchAttr, setSearchVal, searchAttr, searchVal, statusFilter, setStatusFilter, setTab, tabs, tab, successMsg, loggedInUsername, competenceFilterData, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset}){
    
    var options = []
    for (var value of Object.keys(businessobject_data.kundenprojekt.searchAttributes)){
        options.push({value: value, label: businessobject_data.kundenprojekt.searchAttributes[value]})
    }// [{value: '',label: ''}]

    let history = useHistory();
    useEffect(() => {if (accessStatus === data.accessStatus.search && !searchAttr){setSearchAttr(options[0].value)}})
    
    if (accessStatus == data.accessStatus.search){
        return(
            <div className="content-header" id="costumerproject-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Kundenprojekt</div>
                    {!onSelect && role === data.roles.BECKOFFICE && <MultiSelect cssId="statusFilter" filterState={statusFilter} setFilterState={setStatusFilter} selection={{GESPERRT: 'Gesperrt', FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'}/*data.presentation.status*/} />}

                    <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate} />
                
                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} value={optionsFilter(options, 'value', searchAttr)} isSearchable={false} defaultValue={options[0]} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchAttr(s.value)}/>
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>
                
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )
    } else {

        return(
            <div className="content-header">
                <Tabs tabs={tabs} activeTab={tab} setTab={setTab} />
                {object && <StatusCycle status={object.status} accessStatus={accessStatus} businessObject={data.businessObject.customerproject} readOnly={readOnly} role={role} />}
                {accessStatus === data.accessStatus.update && <div className="sum-attributes">
                    {Object.keys(businessobject_data.kundenprojekt.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.kundenprojekt.sumAttributes[attr]}<input disabled value={(object ? object[attr] : '')} /></div>))}
                </div>}
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function:businessObjectFunctions.onCreate, value:'Anlegen & Freigeben', tooltip: "Anlegen und für andere freigeben"}} second={{function:businessObjectFunctions.onCreateLock, value:'Anlegen', tooltip: "Anlegen und für andere sperren"}} disabled={(!somethingChanges ? true : readOnly)} smalls={[{function:() => history.push('/pflegen/kundenprojekt'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
                {accessStatus === data.accessStatus.update && <HeaderButtons main={{function:businessObjectFunctions.onSave, value:'Änderungen speichern', disabled:(!somethingChanges ? true : readOnly), tooltip: "Änderungen speichern und Status nicht verändern"}} opposit={{function:() => history.push('/pflegen/kundenprojekt/' + object?.id), value:'Bearbeiten', disabled:(PermissionForbidden(role, loggedInUsername, object?.id, data.businessObject.customerproject, object)||!object)}} disabled={readOnly} smalls={[{function:businessObjectFunctions.onDelete, value:'Löschen'}, {function:businessObjectFunctions.onLock, value:(object ? (object.status === data.status.GESPERRT ? "Entsperren" : "Sperren") : "Sperren")}, {function:() => history.push('/pflegen/kundenprojekt'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}          
            </div>
        )
    }
}

export default PflegenKopfzeile_Kundenprojekt