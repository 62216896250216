import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import data from '../../data/_data';
import businessobject_data from '../../data/businessobject_data';
import { HeaderButtons, MultiSelect, SingleSelect } from '../_helpers/Kopfzeile';
import Select from 'react-select';
import { object2options } from '../_helpers/functions';
import { TooltipInfo } from '../_helpers/InnerContent';
import StatusCycle from '../_helpers/StatusCycle';

/**
 * Kopfzeilen-Komponente für die Seite "Administration", 
 * Je nach Geschäftsobjekt: Rendert alle folgenden Komponenten zur Einstellung der Filter- und Suchkriterien oder eben aktuelle Informamtonen zum gewählten Benutezr zum Verwalten
 * 
 * @author DHR
 * @param {String} successMsg - Kurze Rückmeldung, dass der Request Erfolgreich war, wird durch Request aus @see Administration_SpecificBenutzer gesetzt
 * @param {Boolean} readOnly - ob nur Leseberechtigung
 * @param {String} businessObject - aktuelles Geschäftsobjekt der Route, unterscheidet sich hier von den offiziellen, feingranularer als 'benutzer'
 * @param {Object} object - gewählter Benutzer der Seite @see Administration_SpecificBenutzer
 * @param {Object} businessObjectFunctions - Funktion der Button (Passwort zurücksetzen, Löschen, Sperren, Änderungen Speichern)
 * @param {Function} setLockedState - Setter zum State der Variable "locked", Filter die Suche
 * @param {String} setRoleFilterState - Setter zum State der Variable "role", Filter die Suche nach der Rolle
 * @param {String} setSearchAttrState - Setter zum State der Variable "SearchAttr", Attribut welches der Suche entsprechen muss
 * @param {String} setSearchValState - Setter zum State der Variable "SearchVal", Attribut welches das Attribut des Objektes in der Suche entsprechen muss
 * @returns {HTML} Kopfzeile der Seiten, Funktionen zur Filter- und Sucheinstellungen
 */
function AdministrationKopfzeile({successMsg, readOnly, accessStatus, businessObject, onSelect, somethingChanges, object, loggedInUser, businessObjectFunctions, setStatusFilterState, setRoleFilterState, setSearchAttrState, setSearchValState}){
    const [statusFilter, setStatusFilter] = useState(false)
    const [roleFilter, setRoleFilter] = useState(0)
    const [searchAttr, setSearchAttr] = useState('')
    const [searchVal, setSearchVal] = useState('')

    let history = useHistory();

    var options = []
    for (var value of Object.keys(businessobject_data[businessObject].searchAttributes)){
        if (value !== 'rolle') {options.push({value: value, label: businessobject_data[businessObject].searchAttributes[value]})}
    }// [{value: '',label: ''}]
    
    const onFilterReset = () => {
        setStatusFilter({})
        setRoleFilter(0)
        setSearchAttr('')
        setSearchVal('')
        if(setStatusFilterState){setStatusFilterState(false)}
        if(setRoleFilterState){setRoleFilterState(0)}
        if(setSearchAttrState){setSearchAttrState('')}
        setSearchValState('')
    }
    const onFilter = () => {
        if(setStatusFilterState){setStatusFilterState(statusFilter)}
        if(setRoleFilterState){setRoleFilterState(roleFilter)}
        if(setSearchAttrState){setSearchAttrState(searchAttr)}
        setSearchValState(searchVal)
    }

    if (accessStatus === data.accessStatus.search){
        if (businessObject === data.businessObject.layout){
            if (!searchAttr) {setSearchAttr('titel')}
            return (
                <div className="content-header" id="layout-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Beraterprofillayout</div>
                    <div id="placeholder"></div>
                    {/* <div className="checkbox-label"><input name="lock" type="checkbox" checked={locked} onChange={e => setLocked(!locked)} />Gesperrt</div> */}
                    <div className="search-bar">
                        <Select className="dropdown" isDisabled={true} classNamePrefix="react-select" name="SearchAttr" options={object2options(businessobject_data.layout.searchAttributes)} isSearchable={false} defaultValue={options[0]} />
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
            )
        }
        return(
            <div className="content-header" id="user-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Benutzer</div>
                    {!onSelect && <MultiSelect filterState={statusFilter} setFilterState={setStatusFilter} selection={{GESPERRT: 'Gesperrt', FREIGABE_QUALITAETSSICHERUNG: 'Freigabe QS'}/*data.presentation.status*/} />}
                    <SingleSelect filterState={roleFilter} setFilterState={setRoleFilter} selection={data.presentation.roles} />
                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} isSearchable={false} defaultValue={options[0]} onChange={s => setSearchAttr(s.value)}/>
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )
    } else {
        if (businessObject === data.businessObject.layout){
            return (
                <div className="content-header">
                <div id="placeholder"></div>
                {object && <StatusCycle status={object.status} accessStatus={accessStatus} businessObject={data.businessObject.layout} readOnly={readOnly} />}
                {accessStatus === data.accessStatus.update && <div className="sum-attributes">
                        {Object.keys(businessobject_data.layout.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.layout.sumAttributes[attr]}<input disabled value={object ? (attr.includes("_") ? (object[attr.substring(0,attr.indexOf("_"))]+', '+object[attr.substring(attr.indexOf("_")+1)])  : object[attr]) : ''} /></div>))}
                    </div>}
                {accessStatus === data.accessStatus.update && <HeaderButtons opposit={{function:() => history.push('/administration/layout/' + object.id), value:'Bearbeiten', disabled:!object}} disabled={readOnly} smalls={[{function:businessObjectFunctions.onDelete, value:'Löschen'}, {function: () => history.push('/administration/layout'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}        
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function: businessObjectFunctions.onCreate, value: 'Anlegen'}} disabled={(!somethingChanges ? true : readOnly)} smalls={[{function:() => history.push('/administration/layout'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
            </div>
            )
        }
        return(
            <div className="content-header">
                <div id="placeholder"></div>
                {object && <StatusCycle status={object.status} accessStatus={accessStatus} businessObject={data.businessObject.user} readOnly={readOnly} />} 
                {accessStatus === data.accessStatus.update && <div className="sum-attributes">
                    <div className="label">Benutzername <input id="currentSelectedUser" type="text" disabled value={(object ? object.benutzername : "")} /></div>{/* <Link to={"/pflegen/berater/"+(object ? object.benutzername : "")}><FontAwesomeIcon id="searchIcon" icon={faSearch} /></Link> */}
                    <div className="label">Mitarbeiter <input id="currentSelectedEmployee" type="text" disabled value={(object ? object.name+", "+object.vorname : "")} /></div>
                </div>}
                {accessStatus === data.accessStatus.update && <HeaderButtons main={{function: businessObjectFunctions.onSave, value: 'Änderungen Speichern', tooltip: "Änderungen speichern und Status nicht verändern", disabled:(!somethingChanges ? true : readOnly)}} opposit={{function:() => history.push('/administration/benutzer/' + object.benutzername), value:'Bearbeiten'}} disabled={readOnly} smalls={[{function: businessObjectFunctions.onPasswordReset, value: 'Passwort zurücksetzen', disabled:(object ? loggedInUser === object.benutzername : false)}, {function:businessObjectFunctions.onDelete, value:'Löschen'}, {function: businessObjectFunctions.onLock, value: (object ? (object.status === data.status.GESPERRT ? "Entsperren" : "Sperren") : "Sperren")}, {function:() => history.push('/administration/benutzer'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}        
                {accessStatus === data.accessStatus.create && <HeaderButtons main={{function: businessObjectFunctions.onCreate, value: 'Anlegen', tooltip: "Anlegen und für andere freigeben"}} /*second={{function:businessObjectFunctions.onCreateLock, value:'Anlegen', tooltip: "Anlegen und für andere sperren"}}*/ disabled={(!somethingChanges ? true : readOnly)} smalls={[{function:() => history.push('/administration/benutzer'), value: (!somethingChanges ? 'Zurück' : 'Änderungen verwerfen'), disabled: 'never'}]} />}
            </div>
        )
    }
}

export default AdministrationKopfzeile